* {
  padding: 0;
  margin: 0;
}

#headline {
  text-align: center;

  .avatar {
    height: 84px;
    border-radius: 100%;
  }
}

.tags {
  padding: 2px 8px 4px 8px;
  margin-left: 4px;
  margin-right: 4px;

  border: solid 1px rgb(183, 183, 183);
  border-radius: 26px;

  &:hover {
    border-color: rgb(183, 242, 255);
    outline: solid 2px rgb(183, 242, 255);
  }
}
